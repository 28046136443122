  const firebaseConfig = {
    apiKey: "AIzaSyBK5vrNTjVXBGiu6DLVavw8OeX19uDxsMY",
    authDomain: "axedelivery-1.firebaseapp.com",
    databaseURL: "https://axedelivery-1.firebaseio.com",
    projectId: "axedelivery-1",
    storageBucket: "axedelivery-1.appspot.com",
    messagingSenderId: "612729787094",
    appId: "1:612729787094:web:c95577208ee3cdb2726f77",
    measurementId: "G-T4SS54E24B"
  };
  // Initialize Firebase
export default firebaseConfig
